import Vue from 'vue';
import Router from '@plugins/VueRouter/index';
import store from '@app2/store';

const PlaylistForm = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Playlist/Show')
const AddContent = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Source')
const UploadContent = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Upload')
const SelectFolder = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Folder')
const SelectMedia = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Index')
const PlaylistEmbed = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Playlist/Embed')

const SectionSelectFolder = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Select/Folder')
const SectionSelectMedia = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Select/File')
const SectionSelectPlaylist = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Select/Playlist')

const LibraryContent = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Upload')
const LibraryFolder = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Folder')
const LibraryFilesBulkActions = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/BulkActions')

const MediaPage = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Show')
const Thumbnail = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Thumbnail')
const Attachment = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Attachment')
const Chapters = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Chapters')
const Cards = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Cards')
const Card = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Card')
const Tags = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Tags')
const BulkTags = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/BulkTags')
const Embed = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Embed')
const Privacy = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Privacy')
const Stats = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Stats')
const Activity = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/Activity')
const DocumentOptions = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/DocumentOptions')

const PlaylistSettingsIndex = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Settings/Index')
const PlaylistSettingsThumbnail = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Settings/Thumbnail')
const PlaylistSettingsAttachment = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Settings/Attachment')
const PlaylistSettingsAutomation = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Settings/Automation')
const PlaylistSettingsSchedule = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Settings/Schedule')

const EmbedCode = () => import(/* webpackChunkName: "library" */ '@app2/modules/Library/Builder/Pages/Media/EmbedCode')

export const beforeEditorLeave = (to, from, next, root) => {
  if ( root.media.hasChanged ) {
    root.$bvModal.show('exit-media-dialog');
    next(false);
    return;
  }
  next();
}

Vue.use(Router);

const lockedFileRoutes = [
  {
    path: '/media/:hash',
    name: 'MediaPage',
    component: MediaPage,
  },
  {
    path: '/media/:hash/thumbnail',
    name: 'Thumbnail',
    component: Thumbnail,
  },
  {
    path: '/media/:hash/attachment',
    name: 'Attachment',
    component: Attachment,
  },
  {
    path: '/media/:hash/chapters',
    name: 'Chapters',
    component: Chapters,
  },
  {
    path: '/media/:hash/cards',
    name: 'Cards',
    component: Cards,
  },
  {
    path: '/media/:hash/tags',
    name: 'Tags',
    component: Tags,
  },
  {
    path: '/media/:hash/privacy',
    name: 'Privacy',
    component: Privacy,
    props: true
  },
  {
    path: '/media/:hash/document-options',
    name: 'DocumentOptions',
    component: DocumentOptions,
    props: true
  },
]

const router = new Router({
  mode: 'abstract',
  name: 'editor-playlist',
  routes: [
    ...lockedFileRoutes,
    {
      path: '/playlist-form/:hash?',
      name: 'PlaylistForm',
      component: PlaylistForm,
    },
    {
      path: '/media/:hash/activity',
      name: 'Activity',
      component: Activity,
      props: true
    },
    {
      path: '/media/:hash/stats',
      name: 'Stats',
      component: Stats,
      props: true
    },
    {
      path: '/add-content',
      name: 'AddContent',
      component: AddContent,
    },
    {
      path: '/upload-content',
      name: 'UploadContent',
      component: UploadContent,
    },
    {
      path: '/select-folder',
      name: 'SelectFolder',
      component: SelectFolder,
    },
    {
      path: '/select-media',
      name: 'SelectMedia',
      component: SelectMedia,
    },
    {
      path: '/playlist-embed',
      name: 'PlaylistEmbed',
      component: PlaylistEmbed,
    },
    {
      path: '/section-select-folder',
      name: 'SectionSelectFolder',
      component: SectionSelectFolder,
    },
    {
      path: '/section-select-media',
      name: 'SectionSelectMedia',
      component: SectionSelectMedia,
    },
    {
      path: '/section-select-playlist',
      name: 'SectionSelectPlaylist',
      component: SectionSelectPlaylist,
    },
    {
      path: '/library-content',
      name: 'LibraryContent',
      component: LibraryContent,
    },
    {
      path: '/library-content/:hash',
      name: 'LibraryContentFolder',
      component: LibraryContent,
    },
    {
      path: '/library-folder',
      name: 'LibraryFolder',
      component: LibraryFolder,
    },
    {
      path: '/library-files-selection-option',
      name: 'LibraryFilesBulkActions',
      component: LibraryFilesBulkActions,
    },
    {
      path: '/media/:hash/cards/:card',
      name: 'Card',
      component: Card,
    },
    {
      path: '/media/:hash/tags',
      name: 'Tags',
      component: Tags,
    },
    {
      path: '/library-tags',
      name: 'LibraryTags',
      component: BulkTags,
    },
    {
      path: '/media/:hash/embed',
      name: 'Embed',
      component: Embed,
    },
    {
      path: '/playlist/:hash/settings',
      name: 'PlaylistSettings',
      component: PlaylistSettingsIndex
    },
    {
      path: '/playlist/:hash/settings/thumbnail',
      name: 'PlaylistSettingsThumbnail',
      component: PlaylistSettingsThumbnail
    },
    {
      path: '/playlist/:hash/settings/attachment',
      name: 'PlaylistSettingsAttachment',
      component: PlaylistSettingsAttachment,
      props: true
    },
    {
      path: '/playlist/:hash/settings/automation',
      name: 'PlaylistSettingsAutomation',
      component: PlaylistSettingsAutomation,
      props: true
    },
    {
      path: '/playlist/:hash/settings/schedule',
      name: 'PlaylistSettingsSchedule',
      component: PlaylistSettingsSchedule,
      props: true
    },
    {
      path: 'hub/code-embed',
      name: 'HubEmbedCode',
      component: EmbedCode,
    }
  ],
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach(async (to, from, next) => {
  if (lockedFileRoutes.some(route => route.name === to.name)) {
    // create a pusher channel
    await store.dispatch('pusher/createChannel', { resourceType: 'file', resourceId: to.params.hash });
  }
  next();
});

router.afterEach((to, from) => {
  if (lockedFileRoutes.some(route => route.name === from.name && !lockedFileRoutes.some(route => route.name === to.name))) {
    // remove the pusher channel
    store.dispatch('pusher/removeChannel', { resourceType: 'file', resourceId: from.params.hash });
  }
})

export default router;
