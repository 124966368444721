import { setupPusherChannel, teardownPusherChannel } from '@app2/utils/pusher';

const namespaced = true;

const state = {
    channels: [], // Each channel: { resourceType, resourceId, isLocked, lockedBy, channelMembers }
};

const mutations = {
    ADD_CHANNEL(state, { resourceType, resourceId }) {
        state.channels.push({
            resourceType,
            resourceId,
            isLocked: false,
            lockedBy: null,
            channelMembers: []
        });
    },
    REMOVE_CHANNEL(state, { resourceType, resourceId }) {
        state.channels = state.channels.filter(channel => 
            !(channel.resourceType === resourceType && channel.resourceId === resourceId)
        );
    },
    UPDATE_CHANNEL_LOCK(state, { resourceType, resourceId, isLocked, lockedBy }) {
        const channel = state.channels.find(ch => 
            ch.resourceType === resourceType && ch.resourceId === resourceId
        );
        if (channel) {
            channel.isLocked = isLocked;
            channel.lockedBy = lockedBy;
        }
    },
    UPDATE_CHANNEL_MEMBERS(state, { resourceType, resourceId, members }) {
        const channel = state.channels.find(ch => 
            ch.resourceType === resourceType && ch.resourceId === resourceId
        );
        if (channel) {
            channel.channelMembers = members.sort((a, b) => 
                new Date(a.joined_at) - new Date(b.joined_at)
            );
        }
    }
};

const getters = {
    GET_CHANNEL: (state) => ({resourceType, resourceId}) => {
        const channel = state.channels.find(ch => 
            ch.resourceType === resourceType && 
            ch.resourceId === resourceId
        );
        return channel;
    },
};

const actions = {
    createChannel({ commit }, { resourceType, resourceId }) {
        return new Promise((resolve, reject) => {
            const channel = state.channels.find(ch => 
                ch.resourceType === resourceType && 
                ch.resourceId === resourceId
            );
            if (channel) return resolve(channel);

            setupPusherChannel({resourceType, resourceId}).then((ch)=>{
                state.channels.push(ch)
                resolve(ch)
            });
        });
    },
    removeChannel({ commit }, { resourceType, resourceId }) {
        commit('REMOVE_CHANNEL', { resourceType, resourceId });
        teardownPusherChannel({ resourceType, resourceId })
    },
    updateLock({ commit }, { resourceType, resourceId, isLocked, lockedBy }) {
        commit('UPDATE_CHANNEL_LOCK', { resourceType, resourceId, isLocked, lockedBy });
    },
    removeChannelMember({ commit, state }, { resourceType, resourceId, memberId }) {
        const channel = state.channels.find(ch => 
            ch.resourceType === resourceType && ch.resourceId === resourceId
        );
        if (channel) {
            const updatedMembers = channel.channelMembers.filter(member => member.id !== memberId);
            commit('UPDATE_CHANNEL_MEMBERS', { 
                resourceType, 
                resourceId, 
                members: updatedMembers 
            });
        }
    },
};

export default {
    namespaced,
    state,
    mutations,
    getters,
    actions
};


