import Echo from '@app2/api/Echo';
import store from "@app2/store";

const channels = [];
const pusher = Echo.connector.pusher;

export const setupPusherChannel = ({resourceType, resourceId}) => {
  const channelName = `presence-lock-${resourceType}.${resourceId}`
  let channelMembers;
  return new Promise((resolve, reject) => {
    const userId = store.getters['auth/GET_USER'].id;
    const channel = pusher.subscribe(channelName);

    channel.bind('pusher:subscription_succeeded', members => {
      channelMembers = Object.values(members.members).sort(
        (a, b) => new Date(a.joined_at) - new Date(b.joined_at)
      );
      const isLocked = members.count > 1;
      const lockedBy = channelMembers.find(member => member.id !== userId);

      resolve({ isLocked, lockedBy, channelMembers, resourceType, resourceId });
    });

    channel.bind('pusher:member_removed', member => {
      const index = channelMembers.findIndex(m => m.id === member.info.id);
      if (index !== -1) {
        channelMembers.splice(index, 1);
      }
      store.dispatch('pusher/updateLock', {resourceType, resourceId, isLocked: channelMembers.length > 1, lockedBy: channelMembers.find(m => m.id !== userId)})
      store.dispatch('pusher/removeChannelMember', {resourceType, resourceId, memberId: member.id})
    })

    channel.bind('pusher:subscription_error', err => {
      reject(err);
    });

    pusher.connect();
    channels.push(channel);
  });
};

export const teardownPusherChannel = ({resourceType, resourceId}) => {
  const channelName = `presence-lock-${resourceType}.${resourceId}`
  const channelToRemove = channels.find(channel => channel.name === channelName);

  if (channelToRemove) {
    channels.splice(channels.indexOf(channelToRemove), 1);
  }
  if (channelToRemove && channelToRemove.name) {
    pusher.unsubscribe(channelToRemove.name);
    channelToRemove.unbind();
  }
};
