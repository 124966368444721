var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "scrollContainer", staticClass: "side-panel side-panel-left" },
    [
      _c(
        "div",
        {
          staticClass:
            "side-panel-header top-sticky-blur top-sticky-blur-neutral d-none-pseudo px-0 pb-0"
        },
        [
          _c("user-avatar", {
            attrs: {
              value: _vm.photoUrl,
              editable: "",
              scroll: _vm.scrollTop,
              size: "xl",
              variant: "default",
              url: "/settings/photo",
              name: "photo"
            },
            on: {
              upload: function($event) {
                return _vm.$store.dispatch("auth/fetchUser")
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "account-settings-meta" }, [
            _c(
              "h3",
              {
                staticClass: "txt-bold mt-2 mb-0 ph-no-capture",
                style: { fontSize: _vm.titleSize + "px" }
              },
              [_vm._v(_vm._s(_vm.user.name))]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "txt-body txt-muted ph-no-capture",
                style: { fontSize: _vm.emailSize + "px" }
              },
              [_vm._v(_vm._s(_vm.user.email))]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "txt-body txt-bold txt-muted",
                style: {
                  opacity: _vm.labelOpacity,
                  maxHeight: _vm.labelHeight + "px"
                }
              },
              [_vm._v(_vm._s(_vm.currentPlanLabel))]
            )
          ]),
          _vm._v(" "),
          _c("hr", {
            staticClass: "mb-0 mt-3",
            style: { opacity: 1 - _vm.labelOpacity }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-body" },
        [
          _vm._l(_vm.childRoutes, function(group) {
            return [
              _c(
                "h4",
                { staticClass: "txt-heading-small txt-muted pt-2 my-3" },
                [_vm._v(_vm._s(group.title))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "side-panel-widget" }, [
                _c(
                  "div",
                  { staticClass: "side-panel-option-wrap" },
                  [
                    _vm._l(group.routes, function(r, index) {
                      return [
                        _c(
                          "div",
                          {
                            key: r.label + "-option",
                            attrs: { id: r.label + "-option" }
                          },
                          [
                            _c("side-panel-option", {
                              attrs: {
                                link: { name: r.route },
                                active:
                                  (r.route === _vm.$route.name ||
                                    (_vm.$route.meta &&
                                      r.route === _vm.$route.meta.parent) ||
                                    (r.route === "AccountSubscription" &&
                                      _vm.$route.name === "AccountAddons")) &&
                                  !r.onClick,
                                label: r.label,
                                description: r.description,
                                "icon-left": r.icon,
                                "icon-right": _vm.getRightIcon(r),
                                "option-index": index,
                                "line-indicator": true,
                                "is-lock": r.locked,
                                tooltip: r.locked && !r.tooltipAction,
                                "custom-right":
                                  (r.route === "AccountWebhooks" &&
                                    _vm.$cant("webhooks")) ||
                                  (r.route === "AccountTeam" && !_vm.hasTeams)
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  r.locked
                                    ? false
                                    : r.onClick
                                    ? r.onClick()
                                    : _vm.$router.push({ name: r.route })
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "tooltip",
                                    fn: function() {
                                      return [
                                        r.locked &&
                                        r.route === "AccountTeam" &&
                                        _vm.isMember
                                          ? _c("span", [
                                              _vm._v(_vm._s(r.tooltip))
                                            ])
                                          : r.locked &&
                                            !r.tooltipAction &&
                                            r.route !== "AccountWebhooks" &&
                                            r.route !== "AccountTeam"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  r.tooltip
                                                    ? r.tooltip
                                                    : "Coming soon"
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "right",
                                    fn: function() {
                                      return [
                                        _c("upgrade-button", {
                                          staticClass: "ml-auto"
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        r.locked && !!r.tooltipAction
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  "custom-class": "tooltip-no-wrap",
                                  variant: "ss",
                                  target: r.label + "-option",
                                  triggers: "hover",
                                  boundary: "viewport"
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "tooltip-cta txt-white",
                                    on: { click: r.tooltipAction }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "tooltip-cta-inner" },
                                      [_vm._v(_vm._s(r.tooltip))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "btn-icon ml-2 p-0" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "icon-regular",
                                            attrs: { viewBox: "0 0 24 24" }
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "#icon-chevron-right"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        index < group.routes.length - 1
                          ? _c("hr", {
                              key: r.label + "-hr",
                              staticClass: "my-0"
                            })
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ])
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          attrs: { variant: "delete", block: "" },
          on: {
            click: function($event) {
              return _vm.$store.dispatch("auth/logout", _vm.$router)
            }
          }
        },
        [
          _c(
            "svg",
            { staticClass: "btn-left", attrs: { viewBox: "0 0 24 24" } },
            [_c("use", { attrs: { "xlink:href": "#icon-exit-right" } })]
          ),
          _vm._v("\n    Logout\n  ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }